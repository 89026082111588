:root {
  --appfuse-font-size: 16px;
}

html {
  font-size: var(--appfuse-font-size);
}

body {
  margin: 0;
  font-family:  -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif, 'PingFang TC', 'Noto Sans TC', 'Microsoft JhengHei';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}